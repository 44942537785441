<template>
  <image-uploader
    class="pixel__pin-uploader"
    v-bind:class="`${currentLayer.preview_url ? 'pixel__pin-uploader--not-empty' : ''}`"
    :quality="1"
    outputFormat="file"
    :preview=false
    :className="['fileinput', { 'fileinput--loaded' : currentLayer.preview_url }]"
    accept="image/*"
    doNotResize="['gif', 'svg']"
    @input="setImage"
    @onUpload="startImageResize"
    @onComplete="endImageResize"
    :key="currentLayer.id"
  >
    <label class="pixel__file-input" v-bind:class="{'not-empty': currentLayer.preview_url }"
           slot="upload-label" for="fileInput">
            <span class="pixel__uploader-preview-wrap" v-if="currentLayer.preview_url">
                <img class="pixel__uploader-preview" :src="currentLayer.preview_url" alt=""/>
            </span>
      <figure v-else>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
        >
          <path
            fill="#d7ccc8"
            class="path1"
            d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
          ></path>
        </svg>
      </figure>
    </label>
  </image-uploader>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'Fileuploader',
  data() {
    return {
      previewImage: ''
    }
  },
  computed: {
    ...mapGetters('design', ['currentLayer']),
  },
  methods: {
    ...mapActions('design', ['uploadFile', 'editFile']),
    startImageResize() {
    },
    endImageResize() {
    },
    async setImage(output) {
      const uploadedFile = await this.uploadFile({
        output,
        project_id: this.$route.params.id
      })

      const file_id = uploadedFile.file_id

      await this.editFile({
        file_id: file_id
      })
    }
  }
}
</script>
<style lang="scss">
.pixel {
  &__uploader-preview-wrap {
    display: flex;
    width: 218px;
    height: 118px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
  }

  &__uploader-preview {
    max-width: 100%;
    max-height: 100%;
    /*border-radius: 6px;*/
  }

  &__pin-uploader {
    border-bottom: 1px solid #d7ccc8;
    margin-bottom: 12px;

    .fileinput {
      position: absolute;
      visibility: hidden;
    }
  }

  &__file-input {
    border: 1px dashed #d7ccc8;
    border-radius: 6px;
    height: 118px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
    cursor: pointer;

    &.not-empty {
      border: none;
    }
  }
}
</style>
